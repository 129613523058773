import { PageHeader } from 'antd';
import ProtectedLayout from '../layouts/protected.layout';

const MortuaryRegister = () => {
  return (
    <ProtectedLayout>
      <PageHeader
        className='site-page-header'
        title='Mortuary Register'
        subTitle='This is where you will see a summary of all the bodies moving through the coldroom'
      />
    </ProtectedLayout>
  );
};

export default MortuaryRegister;
