import './style.scss'


const AppBar = () => {
    return (
        <div className="app-bar">
            Somdaka Funeral Services - Administration Portal
        </div>
    )
}

export default AppBar;