import { PageHeader } from 'antd';
import ProtectedLayout from '../layouts/protected.layout';

const Dashboard = () => {
  return (
    <ProtectedLayout>
      <PageHeader
        className='site-page-header'
        title='Dashboard'
        subTitle='This is where you will see a summary of all the metrics on the system'
      />
    </ProtectedLayout>
  );
};

export default Dashboard;
