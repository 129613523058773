import './App.scss';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import PageNotFound from './pages/404';
import MortuaryRegister from './pages/mortuary-register';
import Login from './pages/login';

function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          {/* <Route path='/' element={<p style={{ textAlign: 'center' }}>Landing Route</p>} /> */}
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Dashboard />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/mortuary-register' element={<MortuaryRegister />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
