import { Button, Checkbox, Col, Divider, Form, Image, Input, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import loginImage from '../assets/images/login.png';

const Login = () => {
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    console.log('Success:', values);
    navigate('/dashboard');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='login-page'>
      <div className='container'>
        <h1>SOMDAKA FUNERAL SERVICES</h1>
        <Divider style={{ minWidth: '60%', width: '60%', margin: '1rem 20%', borderTop: '1px solid #ffac00' }} />
        <h2>ADMINISTRATION PORTAL</h2>
        <Row gutter={16}>
          <Col span={12}>
            <Image src={loginImage} preview={false} />
          </Col>
          <Col span={12}>
            <Form
              layout='vertical'
              name='basic'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              style={{}}>
              <h2>Sign In</h2>
              <Form.Item
                label='Username'
                name='username'
                rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password />
              </Form.Item>

              <Form.Item name='remember' valuePropName='checked'>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
