import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './style.scss';

const SidePanel = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate('/login');
  }

  return (
    <div className='side-panel'>
      <div className='side-panel-header'>
        <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#f5d000' }} />
        <div>
          <p className='username'>Given Somdaka</p>
          <p>given@somdaka.co.za</p>
        </div>
      </div>
      <ul>
        <Link to='/dashboard'>
          <li className={location.pathname.includes('dashboard') ? 'active' : ''}>Dashboard</li>
        </Link>
        <Link to='/mortuary-register'>
          <li className={location.pathname.includes('mortuary-register') ? 'active' : ''}>Mortuary Register</li>
        </Link>
        <li onClick={handleSignOut} className='btn-sign-out'>Sign Out</li>
      </ul>
    </div>
  );
};

export default SidePanel;
