import { Layout } from 'antd';
import AppBar from '../components/appbar';
import SidePanel from '../components/sidepanel';

const { Content, Header, Sider } = Layout;

const ProtectedLayout = (props: any) => {
  return (
    <Layout>
      <Header>
        <AppBar />
      </Header>
      <Layout>
        <Sider>
          <SidePanel />
        </Sider>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  );
};

export default ProtectedLayout;
